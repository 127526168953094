<template>
    <v-container class="pa-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
            <v-icon>mdi-file-cabinet</v-icon>
            Add New Risk to Risk Universe
        </div>
        <div class="pt-3">
            <v-text-field
            v-model="riskName"
            label="Input Risk Name"
            outlined
            >
            </v-text-field>

            <v-select
                :items="RiskClassification"
                item-text="RiskClassification"
                item-value=".key"
                label="Select Classification"
                v-model="selectedRiskClassification"
                outlined
                >
            </v-select>

            <v-text-field
                v-model="selectedRiskUniverse"
                label="Input Risk Universe"
                outlined
                >
            </v-text-field>
            <div>
                <h3>Universal Causes</h3>
                <v-btn
                    @click="addInput('universalCausesInputs')"
                    class="float-right mr-1 add-button-position"
                    fab
                    small
                    color="primary"
                    >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
                <v-text-field 
                    v-for="(field, index) in this.universalCausesInputs" 
                    :key="index" 
                    :placeholder="index !== 0 ? 'Input another Universal Cause' : 'Input a Universal Cause'"
                    v-model="field.text"
                    :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                    @click:append="deleteInput(index, 'universalCausesInputs')"
                    >
                </v-text-field>
            </div>
        

            <div class="mt-6">
                <h3>Universal Impacts</h3>
                <v-btn
                    @click="addInput('universalImpactsInputs')"
                    class="float-right mr-1 add-button-position"
                    fab
                    small
                    color="primary"
                    >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
                <v-text-field 
                    v-for="(field, index) in this.universalImpactsInputs" 
                    :key="index" 
                    :placeholder="index !== 0 ? 'Input another Universal Impact' : 'Input a Universal Impact'"
                    v-model="field.text"
                    :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                    @click:append="deleteInput(index, 'universalImpactsInputs')"
                    >
                </v-text-field>
            </div>

            <div class="mt-6">
                <h3>Universal Treatments</h3>
                <v-btn
                    @click="addInput('universalTreatmentsInputs')"
                    class="float-right mr-1 add-button-position"
                    fab
                    small
                    color="primary"
                    >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
                <v-text-field 
                    v-for="(field, index) in this.universalTreatmentsInputs" 
                    :key="index" 
                    :placeholder="index !== 0 ? 'Input another Universal Treatment' : 'Input a Universal Treatment'"
                    v-model="field.text"
                    :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                    @click:append="deleteInput(index, 'universalTreatmentsInputs')"
                    >
                </v-text-field>
            </div>
            <v-btn 
                color="primary"
                @click="saveRisk">
                Save
            </v-btn>
        </div>
    </v-container>
</template>

<script>
export default {    
    data() {
        return {
            riskName: '',
            selectedRiskClassification: '',
            selectedRiskUniverse: '',
            universalCausesInputs: [
                {
                    text: ''
                }
            ],
            universalImpactsInputs: [
                {
                    text:''
                }
            ],
            universalTreatmentsInputs: [
                {
                    text: ''
                }
            ]
        }
    },
    firestore(){
      return {
        RiskClassification: this.$db.collection('RiskClassification')
      }
    },
    methods: {
    
        deleteInput(index, arr){
            this[arr].splice(index, 1)
        },
        addInput(arr){
            this[arr].push({text: ''})
        },
        mapInput(arr){
          let values = this[arr].map(function(value) {
                return value['text']
            });
            return values;
        },
        findId(id, arr) {
            let find = this[arr].find(e => {
              return e['.key'] === this[id]
            })
            return find
        },

        async saveRisk(){
            let self = this
            let data = {
            CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
            CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            RiskName: this.riskName,
            RiskClassification: this.findId('selectedRiskClassification', 'RiskClassification'),
            RiskUniverse: this.selectedRiskUniverse,
            UniversalCauses: this.mapInput('universalCausesInputs'),
            UniversalImpacts: this.mapInput('universalImpactsInputs'),
            UniversalTreatments: this.mapInput('universalTreatmentsInputs')
            }

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new Risk?`,
                title: `Confirm New Risk`
            })

            if(confirm){
                  self.$db.collection('Risk').add(data)
                 .then(async (doc)=>{
                     let id = doc.id
                      await self.$store.dispatch('logs/createNotifs', {collection:'Risk',documentKey:id,module: 'Risk Universe',action: 'Added New Risk to Risk Universe'}) 
                    self.$dialog.notify.success(`Added New Risk`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })      
                    self.multiDialog = false 
                    self.riskName = ''
                    self.selectedRiskClassification = ''
                    self.selectedRiskUniverse = ''
                    self.universalCausesInputs = [
                        {
                            text: ''
                        }
                    ]
                    self.universalImpactsInputs = [
                        {
                            text:''
                        }
                    ],
                    self.universalTreatmentsInputs = [
                        {
                            text: ''
                        }
                    ]      
                 })
            }
        }
        }
    }
</script>